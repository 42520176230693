import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { CartModifierGroup } from '../utils/cart';
import {
  convertToMap,
  ParsedMenuItem,
  ParsedModifierGroup,
  TopLevelMenuItem,
} from '../utils/menu';
import { GenericMap } from '../utils/types';
import { getModSymbolFromInput } from '../utils/autocomplete';

const selectMenu = (state: RootState) => state.menu;

export function getModSymbolMapping(state: RootState) {
  return state.menu.modSymbolMapping;
}

export function getCodeNameMapping(state: RootState) {
  return state.menu.codeNameMapping;
}

export function generateMenuItemsSelector(currentMessage: string) {
  return (state: RootState): (ParsedMenuItem | TopLevelMenuItem)[] => {
    const {
      cart: { cartItems },
      dialog: {
        selectedItem: dialogSelectedItem,
        selectedItemCartId,
        selectedModGroup: dialogSelectedModGroup,
      },
      menu: { modSymbolMapping, topLevelMenuItems },
    } = state;
    const cartItemsValues = Object.values(cartItems);
    const selectedItem =
      dialogSelectedItem || cartItemsValues[cartItemsValues.length - 1];
    let currentItems: (ParsedMenuItem | TopLevelMenuItem)[] =
      Object.values(topLevelMenuItems);

    let currentMods: (ParsedMenuItem | TopLevelMenuItem)[] = [];

    if (selectedItem) {
      let modGroups: ParsedModifierGroup[] = [];
      let childrenMods: GenericMap<CartModifierGroup>;
      if (dialogSelectedModGroup) {
        modGroups = [dialogSelectedModGroup];
        const inChildModGroups =
          cartItems[selectedItemCartId]?.childModifierGroups[
            dialogSelectedModGroup?.id || ''
          ];
        childrenMods = inChildModGroups
          ? convertToMap([Object.assign(inChildModGroups)])
          : {};
      } else {
        modGroups = Object.values(selectedItem.modifierGroups);
        childrenMods = Object.assign(
          cartItems[selectedItemCartId]?.childModifierGroups || {}
        );
      }
      recurviselyGetMods(childrenMods, modGroups);
      modGroups.forEach((modGroup) => {
        currentMods = [...currentMods, ...Object.values(modGroup.menuItems)];
        currentItems = [...currentItems, ...Object.values(modGroup.menuItems)];
      });
    }

    const modSymbol = getModSymbolFromInput(currentMessage, modSymbolMapping);
    let itemsList = modSymbol ? currentMods : currentItems;

    return itemsList;
  };
}
function recurviselyGetMods(
  childrenMods: GenericMap<CartModifierGroup>,
  modGroups: ParsedModifierGroup[]
) {
  const children = Object.values(childrenMods || {})
    .flatMap((child) => Object.values(child.selectedItems))
    .flatMap(({ modifierGroups, childModifierGroups }) => {
      recurviselyGetMods(childModifierGroups, modGroups);
      return Object.values(modifierGroups);
    });
  modGroups.push(...children);
}

export function selectPersistentVoiceProps(state: RootState) {
  return state.menu.persistentVoiceProps;
}

export const selectMenuRes = createSelector(selectMenu, (menu) => menu.menuRes);

export const selectAvailableCategoryWithTimePeriod = createSelector(
  selectMenu,
  (menu) => menu.availableCategoryWithTimePeriod
);

export const selectTopLevelMenuItems = createSelector(
  selectMenu,
  (menu) => menu.topLevelMenuItems
);

export const selectedMenuVersionSelector = createSelector(
  selectMenu,
  (menu) => menu.selectedMenuVersion
);
export const menuVersionsSelector = createSelector(
  selectMenu,
  (menu) => menu.menuVersions
);
export const prodLiveVersionSelector = createSelector(
  selectMenu,
  (menu) => menu.prodLiveVersion
);

export const posSettingsSelector = createSelector(
  selectMenu,
  (menu) => menu.menuRes?.posSettings || {}
);

export const modSymbolMappingSelector = createSelector(
  selectMenu,
  (menu) => menu.modSymbolMapping
);
